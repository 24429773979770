import React from "react";
import CmsService from "../../services/CmsService";
import { connect } from 'react-redux';
import Cartao from "../cartao/index";
import { RingLoader } from 'react-spinners';
import { FaExclamationTriangle, FaWindowClose } from "react-icons/fa";
import ReactHtmlParser from 'react-html-parser';

class Arquivos extends React.Component {

  constructor(props) {
    super(props);

    this.buscarDocumento = this.buscarDocumento.bind(this);
    this.buscarDocumentoUnico = this.buscarDocumentoUnico.bind(this);
    this.mostrarDados = this.mostrarDados.bind(this);
    this.getNome = this.getNome.bind(this);
    this.getFormato = this.getFormato.bind(this);
    this.formatarTamanho = this.formatarTamanho.bind(this);
    this.inserirImagemConteudo = this.inserirImagemConteudo.bind(this);
    this.handleYearFilter = this.handleYearFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);

    this.state = {
      isLoading: false,
      documentosComuns: [],
      informacoesPagina: [],
      documentosComunsCache: [],
      informacoesPaginaCache: [],
      consultaRealizada: false,
      hasDateFilter: props?.hasDateFilter,
      dateOptions: [],
      yearFilter: '',
    }
  }

  inserirImagemConteudo = text => {
    const path = process.env.REACT_APP_CMS_URL;
    let particoes = text.split("<");
    let retorno = [];
    particoes.forEach(s => {
      let index = s.indexOf("img src=");
      if (index >= 0) {
        let aux = s.slice(0, index + 9) + path + s.slice(index + 9);
        retorno.push(aux);
      } else {
        retorno.push(s);
      }
    })
    return retorno.join("<");
  }

  buscarDocumento = async nomeQuery => await CmsService.getFiles(nomeQuery);

  getFormato = formato => {
    let novoNome = formato.split(".");
    return novoNome[novoNome.length - 1].toUpperCase();
  }

  formatarTamanho = numero => {
    let M = "";
    let retorno = 0;
    if (numero >= (1024 * 1024 * 1024)) {
      M = "GB";
      retorno = parseInt(numero / (1024 * 1024 * 1024));
    } else if (numero >= (1024 * 1024)) {
      M = "MB";
      retorno = parseInt(numero / (1024 * 1024));
    } else if (numero >= 1024) {
      M = "KB";
      retorno = parseInt(numero / (1024));
    } else {
      M = "Byte";
      retorno = numero;
    }
    return "" + retorno + M;
  }

  buscarDocumentoUnico = async ID => await CmsService.getSingleFileById(ID);

  getNome = nome => nome.split(".")[0].toUpperCase();

  mostrarDados = async () => {
    try {
      const queryWEM = {
        codigoInformacoesPagina: "LAI-CTD-PAGINA",
        codigoArquivosPagina: "LAI-CTD-ARQUIVO"
      }
      // const host = process.env.REACT_APP_CMS_URL;
      this.setState({ isLoading: true, documentosComuns: [], informacoesPagina: [] });
      let pasta = this.props.sideBarSelecionado.toLowerCase();
      if (this.props.relativeFolder) { // setando o folder em hardcode para quando uma aba do SIC não tiver um folder específico no WEM
        pasta = this.props.relativeFolder;
      }
      let arquivosComuns = undefined;
      if (!this.state.consultaRealizada) {
        arquivosComuns = await this.buscarDocumento(pasta + "Comum");
        this.setState({ consultaRealizada: true });
      }
      let arquivosEstado = undefined;
      const estado = this.props.estado === "" || this.props.estado === " " ? undefined : this.props.estado.toUpperCase();
      if (estado) {
        arquivosEstado = await this.buscarDocumento(pasta + estado);
        this.setState({ query: pasta + estado });
      }
      let documentosPagina = [];
      let informacoesPagina = [];

      Promise.all([arquivosComuns, arquivosEstado]).then((res, rej) => {
        if (rej) {
          console.log(rej);
          return;
        }
        if (res[0]) {
          if (res[0].status === 200) {
            if (res[0].data.xapi.container.data[0].content.total > 0) {
              let dadosComuns = res[0].data.xapi.container.data[0].content.items;
              dadosComuns.forEach(item => {
                if (item.objectTypeDisplayName === queryWEM.codigoArquivosPagina) {
                  let aux = this.state.documentosComunsCache;
                  aux.push(item);
                  this.setState({ documentosComunsCache: aux });
                } else if (item.objectTypeDisplayName === queryWEM.codigoInformacoesPagina) {
                  let aux = this.state.informacoesPaginaCache;
                  aux.push(item);
                  this.setState({ informacoesPaginaCache: aux });
                }
              });
            }
          }
        }

        // adicionar arquivos pagina

        this.state.documentosComunsCache.forEach(item => documentosPagina.push(item));
        this.state.informacoesPaginaCache.forEach(item => informacoesPagina.push(item));

        // adicionar arquivos pagina


        if (res[1]) {
          if (res[1].status === 200) {
            if (res[1].data.xapi.container.data[0].content.total > 0) {
              let dadosComuns = res[1].data.xapi.container.data[0].content.items;
              dadosComuns.forEach(item => {
                if (item.objectTypeDisplayName === queryWEM.codigoArquivosPagina) {
                  documentosPagina.push(item);
                } else if (item.objectTypeDisplayName === queryWEM.codigoInformacoesPagina) {
                  informacoesPagina.push(item);
                }
              });
            }
          }
        }

        let requisicoes = [];
        documentosPagina.forEach(item => {
          requisicoes.push(this.buscarDocumentoUnico(item.userData.arquivo));
        })
        Promise.all(requisicoes).then((resposta, rejeito) => {
          documentosPagina.forEach(item => {
            resposta.forEach(resp => {
              if (resp.data.xapi.container.data[0].content.item && item.userData.arquivo === resp.data.xapi.container.data[0].content.item.managedObjectId) {
                item.arquivoAnexado = resp.data.xapi.container.data[0].content.item;
              }
            })
          })

          let assuntos = [];
          documentosPagina.forEach(item => assuntos.push(item.userData.assunto));
          assuntos.sort();
          let assuntosUnicos = assuntos.filter((value, index, self) => self.indexOf(value) === index);
          let separados = [];

          assuntosUnicos.forEach(assunto => {
            let aux = { assunto: assunto, data: [] }
            documentosPagina.forEach(item => {
              if (item.userData.assunto === assunto) {
                aux.data.push(item);
              }
            })
            separados.push(aux);
          })

          if (this.state.hasDateFilter) {
            const yearAvaliable = [];
            const yearMemo = {};
            assuntosUnicos.forEach((assunto) => {
              if (assunto.match(/\d{4}/)) {
                const splitYear = assunto.match(/\d{4}/)?.[0];
                if (splitYear && !yearMemo[splitYear]) {
                  yearAvaliable.push(parseInt(splitYear));
                  yearMemo[splitYear] = splitYear;
                }
              }
            });
            yearAvaliable.sort((prev, curr) => prev > curr ? -1 : +(prev === curr))
            this.setState({
              dateOptions: yearAvaliable,
            })
          }

          this.setState({
            documentosComuns: separados,
            informacoesPagina: informacoesPagina.map((valor, index, whole) => {
              return (
                <div style={{ fontFamily: "Lato" }} key={index}>
                  {ReactHtmlParser(this.inserirImagemConteudo(valor.userData.conteudo))}
                </div>
              );
            })
          })

        })
      })
    } catch (error) {
      this.setState({
        informacoesPagina: [error.message].map((item, key) => {
          return (<div key={key} className="mensagemErro">
            <FaExclamationTriangle className="t-icon" />
            <p> {item} </p>
            <FaWindowClose onClick={() => this.setState({ informacoesPagina: "" })} className="botaoFechar" />
          </div>)
        })
      });
    }
    this.setState({ isLoading: false })
  }

  handleYearFilter(arquivo) {
    if (!this.state.hasDateFilter || !this.state.yearFilter) {
      return true;
    }
    return arquivo?.assunto?.includes(this.state.yearFilter);
  }

  handleSort(prev, current) {
    if(!this.state.hasDateFilter) {
      return 0;
    }
    const prevHasYear = prev?.assunto.match(/\d{4}/)?.[0];
    const currentHasYear = current?.assunto.match(/\d{4}/)?.[0];
    if(prevHasYear || currentHasYear) {
      if(!prevHasYear) {
        return 1;
      }
      if(!currentHasYear) {
        return -1;
      }
      return prevHasYear < currentHasYear ? 1 : -1;
    }
    return 0;
  }

  componentDidMount = async () => {
    await this.mostrarDados();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.estado !== this.props.estado) {
      await this.mostrarDados();
    }

  }

  render() {

    return (
      <>
        {this.state.isLoading ? <div className="LoadingDataSpinner"> <RingLoader sizeUnit={"px"} size={150} color={'rgb(37, 127, 245)'} loading={this.state.isLoading} /></div>
          : <div className={this.props.isContraste ? "contraste-display-texto-conteudo" : "display-texto-conteudo"}>{this.state.informacoesPagina}</div>}
        {this.props.children} 
        {this.state.isLoading ?
          <div className="LoadingDataSpinner">
            <RingLoader sizeUnit={"px"} size={150} color={'rgb(37, 127, 245)'} loading={this.state.isLoading} />
          </div>
          :
          <div>
            {this.state.documentosComuns.length > 0 &&
              <>
                <div >
                  <p className="display-titulo">Documentos</p>
                  {this.state.hasDateFilter && this.state.dateOptions?.length > 1 && (
                    <div style={{ marginTop: 20}}>
                      <label htmlFor="year_picker" style={{ marginRight: 4 }}>Filtrar por ano</label>
                      <select id="year_picker" onChange={(e) => {
                        this.setState({ yearFilter: e?.target?.value });
                      }} className="display-anos">
                        <option value="" selected>Exibir todos</option>
                        {this.state.dateOptions.map((date) => (
                          <option key={date} value={date}>{date}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </>
            }
            <div>{this.state.documentosComuns.filter((e) => this.handleYearFilter(e)).sort((prev,curr) => this.handleSort(prev,curr)).map((i, key) => {
              return (
                <div key={key}>
                  <p className="cartao-titulo">{i.assunto}</p>
                  {i.data.map((value, index) => {
                    return (
                      <>
                        {value.arquivoAnexado &&
                          <Cartao key={index} titulo={value.userData.titulo}
                            formato={this.getFormato(value.arquivoAnexado.name)}
                            tamanho={this.formatarTamanho(value.arquivoAnexado.fileSize)}
                            link={process.env.REACT_APP_CMS_URL + value.arquivoAnexado.fullyQualifiedFilePath} />}
                      </>

                    );
                  })}
                </div>
              );
            })}</div>
          </div>}
      </>
    );
  }
}

const mapStateToProps = store => ({
  isContraste: store.principal.isContraste,
  sideBarSelecionado: store.principal.sideBarSelecionado,
  estado: store.principal.estado,
});

export default connect(mapStateToProps)(Arquivos);