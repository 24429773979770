/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import { FaAngleDoubleRight} from "react-icons/fa"

class Breadcrumb extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };

    constructor(props) {
        super(props);
    }

    render() {
        const caminho = this.props.location.pathname.trim().split("/");
        //const caminho = ['sic', 'documentos'];
        const url = caminho.map((itens,key) => {
            if(itens !== ""){
                return <a key={key}> <FaAngleDoubleRight style={{verticalAlign:"sub",padding:"00 5px"}}/> {itens}</a>
            }
            return null;
        })
        return(
            <div className="breadcrumb">
                <p className="display-bread"> <a style={{cursor:"pointer"}} onClick={() => this.props.history.push("/mapa")}>Home</a> {url} </p>
            </div>
        );
    }
}

export default withRouter(Breadcrumb);